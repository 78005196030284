/** @format */

import React, { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Switch from 'react-switch';
import Layout from 'components/Layout/Onboarding/Layout.js';
import { history } from 'stateContainer/history';
import OnboardingFooter from 'components/Layout/Onboarding/Footer';
import { useLocalStorage } from 'utils/hooks';
import { useSelector, useDispatch } from 'react-redux';

import { onboardBrokerService } from 'services/apiService';
import { saveProfilePicture } from 'stateContainer/actions';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

export default function YourServices({ isMobileView, ...props }) {
  const dispatch = useDispatch();

  const { t: translate } = props;

  const {
    heading,
    step_description,
    note_heading,
    note_description,
    buttons,
    options,
    modal,
    pageId,
    errormodal
  } = props.t('lang_onboarding:yourservices');

  const errorMessage = props.t('lang_onboarding:formErrorMessage');

  const {
    onboarding: { profilePicture },
    stepper: { show }
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);

  const stepperText = props.t('lang_onboarding:stepper');
  const finalStepBtnText = props.t('lang_onboarding:finalStepBtnText');
  // const resetLink = props.t('lang_onboarding:reset_link');
  const [brokerId, , removeBrokerId] = useLocalStorage('brokerId', '');

  const resetLink = `${props.t(
    'lang_route:onboarding_welcome'
  )}?brokerId=${brokerId}`;

  const [houseService, setHouseService, removeHouseService] = useLocalStorage(
    'houseService',
    ''
  );
  const [apartmentService, setApartmentService, removeApartmentService] =
    useLocalStorage('apartmentService', '');
  const [cottageService, setCottageService, removeCottageService] =
    useLocalStorage('cottageService', '');
  const [plotService, setPlotService, removePlotService] = useLocalStorage(
    'plotService',
    ''
  );
  const [farmService, setFarmService, removeFarmService] = useLocalStorage(
    'farmService',
    ''
  );

  const [socialSecurityNumber, , removeSocialSecurityNumber] = useLocalStorage(
    'socialSecurityNumber',
    ''
  );
  const [fmiProfileLink, , removeFmiProfileLink] = useLocalStorage(
    'fmiProfileLink',
    ''
  );
  const [phoneNumber, , removePhoneNumber] = useLocalStorage('phoneNumber', '');
  const [addressLine1, , removeAddressLine1] = useLocalStorage(
    'addressLine1',
    ''
  );
  const [addressLine2, , removeAddressLine2] = useLocalStorage(
    'addressLine2',
    ''
  );
  const [zipCode, , removeZipCode] = useLocalStorage('zipCode', '');
  const [city, , removeCity] = useLocalStorage('city', '');
  const [country, , removeCountry] = useLocalStorage('country', '');

  const [activeCountry, , removeActiveCountry] = useLocalStorage(
    'activeCountry',
    ''
  );
  const [activeRegion, , removeActiveRegion] = useLocalStorage(
    'activeRegion',
    ''
  );
  const [activeVisitingAddress, , removeActiveVisitingAddress] =
    useLocalStorage('activeVisitingAddress', '');
  const [description, , removeDescription] = useLocalStorage('description', '');
  const [brokerUsps, , removeBrokerUsps] = useLocalStorage('brokerUsps', '');

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleErrorClose = () => setShowErrorModal(false);
  const handleErrorShow = () => setShowErrorModal(true);
  const [isBrokerUpdateFailedAPIError, setIsBrokerUpdateFailedAPIError] =
    useState(false);

  const [formError, setFormError] = useState('');

  async function handleSubmit() {
    if (
      getBooleanValue(houseService) ||
      getBooleanValue(apartmentService) ||
      getBooleanValue(cottageService) ||
      getBooleanValue(plotService) ||
      getBooleanValue(farmService)
    ) {
      const formData = new FormData();

      formData.append('brokerId', brokerId);
      formData.append('socialSecurityNumber', socialSecurityNumber);
      formData.append('fmiProfileLink', fmiProfileLink);
      formData.append('phoneNumber', phoneNumber);
      formData.append('addressLine1', addressLine1);
      formData.append('addressLine2', addressLine2);
      formData.append('zipCode', zipCode);
      formData.append('city', city);
      formData.append('country', country);
      formData.append('activeRegion', activeRegion);
      formData.append('activeCountry', activeCountry);
      formData.append('activeVisitingAddress', activeVisitingAddress);
      formData.append('description', description);
      formData.append('brokerUsps', brokerUsps);
      formData.append('houseService', getBooleanValue(houseService));
      formData.append('apartmentService', getBooleanValue(apartmentService));
      formData.append('cottageService', getBooleanValue(cottageService));
      formData.append('plotService', getBooleanValue(plotService));
      formData.append('farmService', getBooleanValue(farmService));
      formData.append('profilePicture', profilePicture[0]);
      formData.append('region', activeRegion);

      try {
        setLoading(true);
        await onboardBrokerService(formData);
        handleShow();
        clearLocalStorage();
      } catch (error) {
        setLoading(false);
        if (error.response.data?.error.includes('BrokerUpdateFailedError')) {
          setIsBrokerUpdateFailedAPIError(true);
        }
        handleErrorShow();
        // clearLocalStorage();
      } finally {
        setLoading(false);
      }
    } else {
      setFormError(errorMessage);
    }
  }

  function clearLocalStorage() {
    removeSocialSecurityNumber();
    removeFmiProfileLink();
    removePhoneNumber();
    removeAddressLine1();
    removeAddressLine2();
    removeZipCode();
    removeCity();
    removeCountry();
    removeActiveRegion();
    removeActiveCountry();
    removeActiveVisitingAddress();
    removeDescription();
    removeBrokerUsps();
    removeHouseService();
    removeApartmentService();
    removeCottageService();
    removePlotService();
    removeFarmService();
    removeBrokerId();
    removeActiveRegion();
    dispatch(saveProfilePicture([]));
  }

  function getSwitchOptionFunc(index) {
    switch (index) {
      case 0:
        return setHouseService((currentState) => !currentState);
      case 1:
        return setApartmentService((currentState) => !currentState);
      case 2:
        return setCottageService((currentState) => !currentState);
      case 3:
        return setPlotService((currentState) => !currentState);
      case 4:
        return setFarmService((currentState) => !currentState);

      default:
        break;
    }
  }

  function getBooleanValue(value) {
    if (value.length === 0 || value === false) return false;
    return true;
  }

  function getSwitchOptionValue(index) {
    switch (index) {
      case 0:
        return getBooleanValue(houseService);
      case 1:
        return getBooleanValue(apartmentService);
      case 2:
        return getBooleanValue(cottageService);
      case 3:
        return getBooleanValue(plotService);
      case 4:
        return getBooleanValue(farmService);

      default:
        break;
    }
  }

  function completeOnboarding() {
    history.push('/');
  }

  return (
    <>
      <Layout
        isMobileView={isMobileView}
        stepperText={stepperText}
        pageId={pageId}
        heading={heading}
        step_description={step_description}
        note_heading={note_heading}
        note_description={note_description}>
        {!show && (
          <>
            {formError.length > 0 && (
              <AlertBox
                type='danger'
                text={formError}
                icon={<AiOutlineExclamationCircle />}
                iconConfig={{ size: '18px' }}
              />
            )}
            <div className='onboarding-myservices-grid'>
              {options.map((option, index) => (
                <Fragment key={index}>
                  <span className='text2r'>{option}</span>
                  <div className='onboarding-myservices-row'>
                    <Switch
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor={'#cf6584'}
                      onChange={() => getSwitchOptionFunc(index)}
                      checked={getSwitchOptionValue(index)}
                    />
                    <span
                      className={`text4 onboarding-myservices-switch-text ${
                        getSwitchOptionValue(index) &&
                        'onboarding-myservices-pink-text'
                      }`}>
                      {getSwitchOptionValue(index)
                        ? translate('lang_common:yes:text')
                        : translate('lang_common:no:text')}
                    </span>
                  </div>
                </Fragment>
              ))}
            </div>
          </>
        )}
        <OnboardingFooter
          loading={loading}
          buttons={buttons}
          finalStepBtnText={finalStepBtnText}
          btnPrevFunction={() => history.push(buttons[0].url)}
          btnNextFunction={() => handleSubmit()}
        />
      </Layout>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}>
        <Modal.Body>
          <p className='text1 pink-text'>{modal.heading}</p>
          <p className='text2r'>{modal.text_1}</p>
          <p className='text2r text-left-align'>{modal.text_2}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-white'
            onClick={() => completeOnboarding()}>
            {modal.btnText}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showErrorModal}
        onHide={handleErrorClose}
        keyboard={false}
        backdrop='static'>
        <Modal.Body>
          <p className='text1 pink-text'>{errormodal.heading}</p>
          {isBrokerUpdateFailedAPIError ? (
            <p className='text2r'>{errormodal.brokerUpdateFailedError.text}</p>
          ) : (
            <>
              <p className='text2r'>{errormodal.text_1}</p>
              <p className='text2r text-left-align'>{errormodal.text_2}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='btn btn-white'
            onClick={() => history.push(resetLink)}>
            {errormodal.btnText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
