/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import StickyBox from 'react-sticky-box';
import Flag from 'components/FlagComponent';
import { QALocalEnvOnly } from 'utils';

export default function ObjectListingHeader(props) {
  const {
    estate,
    title,
    buttonText,
    priceText,
    handleClick,
    startingPrice,
    finalPrice,
    biddingStarted,
    isBidding,
    isFinalPrice,
    finalPriceText,
    priceTextLabel,
    countryCode,
    country,
    extraData
  } = props;

  function handleButtonClick(item) {
    return handleClick(item.text);
  }

  function getPriceText() {
    if (isFinalPrice) {
      return `${finalPrice} ${text.sek.label} ${finalPriceText}`;
    } else if (isBidding) {
      return biddingStarted;
    } else {
      return startingPrice;
    }
  }

  const { t } = useTranslation();

  const text = t('lang_common:object_listing:basicInfoObj');

  const Address = () => {
    let address = [];
    if (estate?.baseInformation?.address?.streetAddress) {
      address.push(estate?.baseInformation?.address?.streetAddress);
    }

    if (estate?.baseInformation?.address?.municipality) {
      address.push(estate?.baseInformation?.address?.municipality);
    }

    if (country) {
      address.push(country);
    }

    return address.join(', ');
  };

  const PriceDisplay = () => {
    const Price = () => {
      const currency = extraData?.price?.currency;

      if (currency) {
        // has a currency value
        if (currency !== 'SEK') {
          // is an other currency

          if (extraData.price?.exchangeRate) {
            // has an exchange rate
            const sekCalculation =
              Number(extraData.price?.startingPrice) *
              Number(extraData.price?.exchangeRate);
            return (
              <div className='object-listing-header-price-currency'>
                <h4 className='object-listing-header-price'>
                  {`${priceTextLabel} (${currency}): ${text.sek.label} ${priceText}`}
                </h4>
                <QALocalEnvOnly>
                  <span className='object-listing-header-calculated-price'>
                    Approximately {sekCalculation} SEK
                  </span>
                </QALocalEnvOnly>
              </div>
            );
          } else {
            // no exchange rate
            return (
              <h4 className='object-listing-header-price'>
                {`${priceTextLabel} (${currency}): ${text.sek.label} ${priceText}`}
              </h4>
            );
          }
        } else {
          // is SEK
          return (
            <h4 className='object-listing-header-price'>
              {`${priceTextLabel} (${currency}): ${text.sek.label} ${priceText}`}
            </h4>
          );
        }
      } else {
        // does not have a currency value
        return (
          <h4 className='object-listing-header-price'>
            {`${priceTextLabel} (-): ${text.sek.label} ${priceText}`}
          </h4>
        );
      }
    };

    return (
      <div className='object-listing-header-right'>
        <div className='object-listing-header-top'>
          <Price />
        </div>
        <div className='object-listing-header-bottom'>
          <div className='object-listing-header-price'>{getPriceText()}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='object-listing-header'>
        <div className='object-listing-header-left'>
          <div className='object-listing-header-top'>
            <h3>{title}</h3>
          </div>
          <div className='object-listing-header-bottom'>
            <div className='object-listing-header-icon icon-location'></div>
            <div className='object-listing-header-address text2r'>
              <Address />
            </div>
            <Flag countryCode={countryCode} />
          </div>
        </div>

        <Flag countryCode={countryCode} />
      </div>
      <StickyBox offsetTop={0} offsetBottom={20} className='sticky-box'>
        <div className='property-header-container'>
          <div className='property-header'>
            {buttonText &&
              buttonText.map((item) => (
                <Button
                  className={`btn btn-white object-listing-button-item`}
                  key={item.text}
                  onClick={() => handleButtonClick(item)}>
                  {item.text}
                </Button>
              ))}
          </div>
          <PriceDisplay />
        </div>
      </StickyBox>
    </>
  );
}
