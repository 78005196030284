/** @format */
import React from 'react';

import getUrlParams from 'utils/getUrlParams';

export const ENV_MODES = {
  DEV: 'DEV',
  QA: 'QA',
  PROD: 'PROD'
};

const getCurrentEnv = () => {
  const qaRegex = /\/qa\.friamaklare\.se\//;
  const prodRegex = /\/.friamaklare\.se\//;
  const localhostRegex = /\/localhost:\d+/;

  const currentUrl = window.location.href;

  if (qaRegex.test(currentUrl)) {
    console.log('QA');
    return ENV_MODES.QA;
  } else if (prodRegex.test(currentUrl)) {
    console.log('PROD');
    return ENV_MODES.PROD;
  } else if (localhostRegex.test(currentUrl)) {
    console.log('DEV');
    return ENV_MODES.DEV;
  } else {
    console.log('Invalid ENV');
    return null;
  }
};

const checkIsQALocalEnv = () => {
  return [ENV_MODES.QA, ENV_MODES.DEV].includes(getCurrentEnv());
};

/**
 * children of this element is visible in QA and DEV env only
 */
// eslint-disable-next-line no-unused-vars
export const QALocalEnvOnly = ({ children }) => {
  if (checkIsQALocalEnv()) {
    // is QA or local dev
    return <></>;
  } else {
    return <></>;
  }
};

const utils = {
  getUrlParams,
  getCurrentEnv
};
export default utils;
