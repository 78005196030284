/** @format */

import React from 'react';
import config from 'config';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { NAV_KEYS } from '../common';
import { QALocalEnvOnly } from 'utils';

import './styles.scss';

const { brokerLoginUrl } = config.app;

const Header = ({
  buttonText,
  homepageUrl = '/',

  setDisplayContactSeller,
  t
}) => {
  const router = useHistory();
  // const location = useLocation();

  const Logo = () => (
    <Link to={homepageUrl} className='logo'>
      <div className='logo-fria-maklare-dark' />
    </Link>
  );

  const NavLinksBar = () => {
    const NavElement = ({ activeClass, onClick, label }) => (
      <span className={activeClass} onClick={onClick}>
        {label}
      </span>
    );

    const NavLink = ({ label, to = null, navKey }) => {
      if ([NAV_KEYS.SELL, NAV_KEYS.BUY, NAV_KEYS.APPRAISE].includes(navKey)) {
        // show leads popup

        return (
          <NavElement
            label={label}
            onClick={() => {
              setDisplayContactSeller(true);
            }}
          />
        );
      } else {
        // redirect
        // const activeClass = location?.pathname === `/${to}` ? 'active' : '';

        const redirectionHandler = () => {
          if (to) {
            router.push(to);
          }
        };

        return (
          <NavElement
            activeClass={false}
            label={label}
            onClick={() => redirectionHandler()}
          />
        );
      }
    };

    const ServicesDropdown = () => (
      <QALocalEnvOnly>
        <Dropdown>
          <Dropdown.Toggle variant='light'>
            {t('lang_header:services:button:text')}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink
                label={t('lang_header:invest:button:text')}
                to={t(`lang_route:registration_invest_page`)}
                navKey={NAV_KEYS.INVEST}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink
                label={t('lang_header:monitor:button:text')}
                to={t(`lang_route:registration_monitor_page`)}
                navKey={NAV_KEYS.MONITOR}
              />
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink
                label={t('lang_header:renovate:button:text')}
                to={t(`lang_route:registration_renovate_page`)}
                navKey={NAV_KEYS.RENOVATE}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </QALocalEnvOnly>
    );

    return (
      <div className='header-nav-links'>
        <NavLink
          label={t('lang_header:sell:button:text')}
          navKey={NAV_KEYS.SELL}
        />
        <NavLink
          label={t('lang_header:appraise:button:text')}
          navKey={NAV_KEYS.APPRAISE}
        />
        <NavLink
          label={t('lang_header:buy:button:text')}
          navKey={NAV_KEYS.BUY}
        />
        <NavLink
          label={t('lang_header:abroad:button:text')}
          to='/abroad'
          navKey={NAV_KEYS.ABROAD}
        />

        <ServicesDropdown />
      </div>
    );
  };

  const ButtonsSection = () => {
    return (
      <div className='header-buttons-section'>
        <Button
          onClick={() => {
            window.location.href = brokerLoginUrl;
          }}
          className='btn btn-white btn btn-primary'
          style={{
            marginRight: '10px'
          }}>
          {buttonText[0]?.text}
        </Button>
        <Button
          onClick={() => router.push('registrera')}
          className='btn btn-pink btn btn-primary'>
          {buttonText[1]?.text}
        </Button>
      </div>
    );
  };

  return (
    <>
      {/* <div className='top-blur-section' /> */}
      <div className='header-v2'>
        <div className='header-left-container'>
          <Logo />
        </div>
        <div className='header-middle-container'>
          <NavLinksBar />
        </div>
        <div className='header-right-container'>
          <ButtonsSection />
        </div>
      </div>
    </>
  );
};

export default Header;
